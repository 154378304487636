<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-reporte-tepContratoInt"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar reporte de contratos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="id_nomina" class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="form.empresa"
                      placeholder="Empresas"
                      label="razon_social"
                      class="form-control p-0"
                      :options="listasForms.empresas"
                      @input="selectEmpresa()"
                    ></v-select>
                    <label class="col-md-5">Bloque</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="form.bloque"
                      placeholder="Bloque"
                      label="nombre"
                      :options="listasForms.bloques"
                      class="form-control p-0"
                      @input="selectBloque()"
                    ></v-select>
                    <label for="id_nomina" class="col-md-5"
                      >Tipo de contrato</label
                    >
                    <select
                      class="form-control form-control-sm"
                      v-model="form.tipoContrato"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_contrato in listasForms.tipoContrato"
                        :key="tipo_contrato.numeracion"
                        :value="tipo_contrato.numeracion"
                      >
                        {{ tipo_contrato.descripcion }}
                      </option>
                    </select>
                    <label for="id_nomina" class="col-md-5"
                      >Objeto de contrato</label
                    >
                    <select
                      class="form-control form-control-sm"
                      v-model="form.objetoContrato"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="objeto_contrato in listasForms.objetoContrato"
                        :key="objeto_contrato.numeracion"
                        :value="objeto_contrato.numeracion"
                      >
                        {{ objeto_contrato.descripcion }}
                      </option>
                    </select>
                    <label for="id_nomina" class="col-md-5"
                      >Fecha inicial</label
                    >
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_ini"
                    />
                    <label for="id_nomina" class="col-md-5">Fecha final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_fin"
                    />
                    <label for="id_nomina" class="col-md-5">Valor</label>
                    <input
                      type="num"
                      class="form-control form-control-sm"
                      v-model="form.valor"
                    />
                    <label for="id_nomina" class="col-md-5"
                      >Vigencia póliza</label
                    >
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.vigenciaPoliza"
                    />
                    <label for="id_nomina" class="col-md-5">Pernocte</label>
                    <input
                      type="num"
                      class="form-control form-control-sm"
                      v-model="form.pernocte"
                    />
                    <label for="id_nomina" class="col-md-5"
                      >Límite peajes</label
                    >
                    <input
                      type="num"
                      class="form-control form-control-sm"
                      v-model="form.limitePeajes"
                    />
                    <label for="id_nomina" class="col-md-5">Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estados in listasForms.estado"
                        :key="estados.numeracion"
                        :value="estados.numeracion"
                      >
                        {{ estados.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "TepContratoInternoExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        empresa: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        bloque: null,
        bloque_id: null,
        tipoContrato: null,
        objetoContrato: null,
        valor: null,
        vigenciaPoliza: null,
        pernocte: null,
        limitePeajes: null,
        estado: null,
      },
      listasForms: {
        empresas: [],
        bloque: [],
        tipoContrato: [],
        objetoContrato: [],
        estado: [],
      },
    };
  },

  methods: {
    getEmpresas() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    selectEmpresa() {
      this.form.empresa_id = this.form.empresa.id;
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    selectBloque() {
      this.form.bloque_id = this.form.bloque.id;
    },

    getTipoContrato() {
      axios.get("/api/lista/26").then((response) => {
        this.listasForms.tipoContrato = response.data;
      });
    },

    getObjetoContrato() {
      axios.get("/api/lista/27").then((response) => {
        this.listasForms.objetoContrato = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estado = response.data;
      });
    },

    generarListadoExcel() {
      // this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/contratosInternos/reporte",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.getBloques();
    this.getEmpresas();
    this.getTipoContrato();
    this.getObjetoContrato();
    this.getEstados();
    this.cargando = false;
  },
};
</script>
